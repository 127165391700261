<template>
  <div class="flex flex-col space-y-4">
    <div class="text-xl font-bold">{{ detail.title }}</div>
    <img
      v-if="!isYoutube"
      class="rounded-lg shadow-xl object-fill object-center w-full"
      :src="detail.imageUrl">
    <div
      v-if="date"
      class="text-base text-gray-400 text-right">{{ date }}</div>
    <YoutubeIframe
      v-if="isYoutube"
      :youtube-id="detail.youtubeVideoId"
    />
    <div class="text-base text-gray-800" v-html="detail.contents">
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
import { mapState, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { homeConfig } from '@/config'
import { gunsaImpression } from '@/utils/trackers'

const YoutubeIframe = defineAsyncComponent(() => import('@/components/common/YoutubeIframe.vue'))
export default {
  name: 'DetailArticle',
  components: {
    YoutubeIframe
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('article', ['detail']),
    articleId () {
      return this.$route.params.id
    },
    date () {
      return this.detail?.date && dateFormater(this.detail.date)
    },
    isYoutube () {
      return this.detail.type === homeConfig.articleType.youtube
    },
    isPdf () {
      return this.detail.type === homeConfig.articleType.pdf
    }
  },
  methods: {
    ...mapActions('article', ['fetchDetail', 'resetDetail']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    initPage () {
      this.showLoading()
      this.resetDetail()
      this.fetchDetail({
        id: this.articleId,
        success: this.handleSuccess,
        fail: this.handleFail
      })
    },
    handleSuccess () {
      this.hideLoading()
      if (this.isPdf) {
        this.$router.replace({
          name: 'Pdf',
          query: {
            id: this.detail.pdfId
          }
        })
      }
      gunsaImpression({
        page: 'Article',
        title: this.detail.title,
        value: `www.gkigunsa.com/detail/${this.detail.id}`
      })
    },
    handleFail () {
      this.hideLoading()
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
